import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useToasts } from "react-toast-notifications";
import Papa from "papaparse";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../context/ai-model.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { AppContext, useAppContext } from "../../../../context/app.context";
import constant, { ToastType } from "../../../../helper/constant";
import CustomDropdown from "./../../../common/CustomDropdown";
import CustomLoader from "./../../../common/CustomLoader";
import { requestAutomatedTargets } from "../../../../service/ai-model.service";

const DefaultForm = (props) => {
  const { onSubmit } = props;
  const [read, setRead] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const {
    hideRightMenu,
    aiDataSelectedForm,
    saveAIModelForm,
    switchToSavedModel,
  } = useAIModelContext();
  const [headerList, setHeaderList] = useState([]);
  const [targets, setTargets] = useState([]);
  const [features, setFeatures] = useState([]);
  const [csv, setCSV] = useState("");
  const [target, setTarget] = useState("");
  const [feature, setFeature] = useState([]);
  const [form, setForm] = useState({ error: {} });
  const { addToast } = useToasts();
  const params = useParams();
  const { showAlert } = useAppContext();
  useEffect(() => {
    if (aiDataSelectedForm && aiDataSelectedForm.target_column) {
      setCSV(aiDataSelectedForm.gcs_source);
      setTarget(aiDataSelectedForm.target_column);
      setFeature(aiDataSelectedForm.features_column);
      setRead(true);
    }
  }, [aiDataSelectedForm]);

  useEffect(() => {
    if (csv) {
      Papa.parse(csv, {
        header: true,
        error: (e) =>
          showAlert(
            e || constant.MESSAGES.COMMON_ERROR_MESSAGE,
            ToastType.ERROR,
          ),
        complete: (r) => {
          setCSVData(r.data.slice(0, 4));
          setHeaderList(r.meta.fields);
          setTargets(r.meta.fields);
          setFeatures(r.meta.fields);
        },
      });
    }
  }, [csv]);

  // useEffect(() => {
  //     if (target) {
  //         const list = [...targets].filter(zz => !target.includes(zz));
  //         setFeatures(list);
  //     }
  // }, [target])

  const handleValdiation = () => {
    let isValid = true;
    let error = {};
    if (!csv) {
      isValid = false;
      error["csv"] = "csv required.";
    }

    if (!target || target.length === 0) {
      isValid = false;
      error["target"] = "Target required.";
    }
    if (!feature || feature.length === 0) {
      isValid = false;
      error["feature"] = "Feature required.";
    }
    setForm({ error });
    return isValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValdiation()) {
      const { id, modelId, model_name, parentId } = aiDataSelectedForm;
      const payload = {
        id,
        csv,
        feature,
        target,
        modelId,
        templateId: params.id,
        model_name,
        parentId,
      };
      onSubmit(payload);
      let formdata = new FormData();
      formdata.append("id", id);
      formdata.append("csv", csv);
      formdata.append("feature", feature.join(","));
      formdata.append("target", target);
      formdata.append("templateId", params.id);
      formdata.append("modelId", modelId);
      saveAIModelForm(formdata, (data) =>
        switchToSavedModel({ ...data, model_name, parentId }),
      );
    }
  };

  const onAutomatedTarget = (data) => {
    if (data && data.target_column) {
      const features = [
        ...data.column_transformations.map((item) => {
          const feature = item.split(".");
          if (feature.length) {
            return feature[0];
          }
          return feature;
        }),
      ];
      setTarget(data.target_column);
      setFeature(features);
    }
  };

  const renderModelDataForm = () => {
    return (
      <>
        <div className="">
          {read && <div className="h6  f-13">{`Data : ${csv?.name}`}</div>}
          {!read && (
            <>
              <b className="pb-1 custom-dropdown-label">Select file</b>
              <div
                className="file-upload-wrapper2 border-bottom "
                data-text="Select file!"
                style={{ borderColor: form.error["file"] ? "red" : "" }}
              >
                <input
                  name="file-upload"
                  id="df-file-upload"
                  type="file"
                  className="file-upload-field2"
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </div>
            </>
          )}
          {!read && csv && <div className="df-file-selected">{csv?.name}</div>}
        </div>
        <div
          className={`d-flex align-items-center justify-content-start gap-2 py-2 `}
        >
          <p className="m-0 p-0 bold">Automate training</p>
          <AutomatedTargetSwitch
            data={csvData}
            disabled={!csv}
            onChange={(data) => onAutomatedTarget(data)}
          />
        </div>
        <div className="">
          {read && <div className="h6  f-13">Target : {target}</div>}
          {!read && (
            <>
              <b className="pb-1 custom-dropdown-label">Target</b>
              <CustomDropdown
                hasError={form.error["target"]}
                name=""
                options={targets}
                value={target}
                onChange={(e) => setTarget(e)}
              />
            </>
          )}
        </div>
        <div className="">
          {read && <div className="h6  f-13">Feature : {feature}</div>}
          {!read && (
            <>
              <b className="pb-1 custom-dropdown-label">Features</b>
              <Multiselect
                style={{
                  multiselectContainer: {
                    borderColor: form.error["feature"] ? "red" : "",
                  },
                }}
                isObject={false}
                onSelect={(list, item) => setFeature([...feature, item])}
                onRemove={(list, item) =>
                  setFeature([...feature].filter((zz) => zz !== item))
                }
                placeholder="Select Features"
                avoidHighlightFirstOption
                options={features} // Options to display in the dropdown
                selectedValues={[...feature]} // Preselected value to persist in dropdown
                displayValue="label" // Property name to display in the dropdown options
              />
            </>
          )}
        </div>
      </>
    );
  };

  const isSaveActive = () => {
    if ((feature, csv, target)) return false;
    return true;
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faEdit} />
              Modify
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn2 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
              onClick={() => hideRightMenu()}
            >
              Close
            </button>
          </div>
        </>
      );

    return (
      <>
        <div className="form-group">
          <button
            type="submit"
            disabled={isSaveActive()}
            className="btn2 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          >
            save
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].name.endsWith(".csv")) {
        const selectedFile = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          const lines = content.split("\n").length;
          if (lines < constant.minCsvLines) {
            const error = { ...form.error };
            error["csv"] = "csv required";
            setForm({ error });
            showAlert(
              `CSV file must have at least ${constant.minCsvLines} lines`,
              ToastType.WARNING,
              false,
            );
          } else {
            setCSV(selectedFile);
            const error = { ...form.error };
            error["csv"] = "";
            setForm({ error });
          }
        };

        reader.readAsText(selectedFile);
      } else showAlert("Please upload valid csv", ToastType.WARNING, false);
    }
  };

  const render = () => {
    if (aiDataSelectedForm) {
      return (
        <div className="social-medial-form">
          <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit}>
            {renderModelDataForm()}
            <div className="form-group">{renderActionButtons()}</div>
          </form>
        </div>
      );
    }
  };
  return render();
};
export default DefaultForm;

export const DefaultFilledFrom = () => {
  const { aiDataSelectedForm, hideRightMenu } = useAIModelContext();

  const renderData = () => {
    return (
      <>
        {renderTargets()}
        {renderFeatures()}
      </>
    );
  };
  const renderTargets = () => {
    const { target_column } = aiDataSelectedForm;
    if (target_column) {
      const targets = target_column.split(",");
      if (targets.length)
        return (
          <div>
            <h5>Targets</h5>
            <ul className="ul-list ml-4">
              {targets.map((target, rowIndex) => (
                <li className="pb-1 text-capitalize" key={rowIndex}>
                  {target}
                </li>
              ))}
            </ul>
          </div>
        );
    }
  };

  const renderFeatures = () => {
    const { features_column } = aiDataSelectedForm;
    if (features_column) {
      const features = Object.keys(JSON.parse(features_column));
      return (
        <div>
          <h5>Features</h5>
          <ul className="ul-list ml-4">
            {features.map((feature, rowIndex) => (
              <li className="pb-1 text-capitalize" key={rowIndex}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const renderAIFilledForm = () => {
    return (
      <div className="py-3 pb-2 gap-2 d-flex flex-column">{renderData()}</div>
    );
  };

  const render = () => {
    return renderAIFilledForm();
  };
  return render();
};

export const AutomatedTargetSwitch = (props) => {
  const { onChange, data, disabled } = props;
  const { aiDataSelectedForm } = useAIModelContext();
  const { modelId } = aiDataSelectedForm;
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status) {
      setLoading(true);
      const payload = { data, modelId };
      requestAutomatedTargets(payload)
        .then((data) => onChange(data))
        .catch((ex) => console.log(ex))
        .finally(() => setLoading(false));
    }
  }, [status]);

  return (
    <>
      {loading && <CustomLoader full />}
      <div className="checkbox-wrapper-64 d-flex">
        <label className="switch m-0">
          <input
            disabled={disabled}
            type="checkbox"
            checked={status}
            onChange={() => setStatus(!status)}
          />
          <span className="slider"></span>
        </label>
      </div>
    </>
  );
};
