import React, { useEffect, useState } from "react";
import ChatbotHistory from "./ChatbotHistory";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import ChatQueyResult from "./ChatQueryResult";
import ChatbotFiles from "./ChatbotFiles";
import AskMeRightPanel from "./AskMeRightPanel";
import images from "../../../helper/images";

const ChatbotResult = () => {
  const {
    updateCurrentQuery,
    currentQuery,
    getAnswerForQuery,
    queryHistory,
    updateShowModal,
    queryTasks,
    queryWorkflows,
    clearAskMe,
  } = useAIAskMeContext();
  const [loading, setLoading] = useState({ workFlow: false, doc: false });
  const [query, setQuery] = useState("");
  const [force, setForce] = useState(false);
  const [checkedModel, setCheckedModel] = useState(true);
  const [chatGpt, setChatGpt] = useState(true);
  const [checkedDoc, setCheckedDoc] = useState(true);

  useEffect(() => {
    if (currentQuery) {
      setLoading({ workFlow: true, doc: true });
      setQuery(currentQuery);
      getAnswerForQuery(chatGpt, () => {
        setLoading({ workFlow: false, doc: true });
      });
    }
  }, [currentQuery, force]);

  const renderDefaultQuestionList = () => {
    const shortHistory = queryHistory.slice(0, 4);
    return (
      <ul className="question-list">
        {shortHistory.map((item, qIndex) => (
          <li key={`query-list-${qIndex}`}>
            <button
              onClick={() => {
                updateCurrentQuery(item.id);
              }}
            >
              {item.id}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const onQuerySubmit = (e) => {
    e.preventDefault();
    if (query !== currentQuery) {
      updateCurrentQuery(query);
    } else {
      setForce(!force);
    }
  };

  const renderChatResult = () => {
    if (queryTasks.length || queryWorkflows.length)
      return (
        <ChatQueyResult
          checkedDoc={checkedDoc}
          checkedModel={checkedModel}
          chatGpt={chatGpt}
          stopLoader={() => {
            setLoading({ ...loading, doc: false });
          }}
        />
      );
    // return renderDefaultQuestionList()
  };

  const renderCloseModal = () => {
    return (
      <div
        className="crossdismiss"
        onClick={() => {
          updateShowModal(false);
          clearAskMe();
        }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 30L30 10M10 10L30 30"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const renderInlineLoader = () => {
    return (
      <div className="ask-me-loader-container">
        <img src={images.loaderGif} width="30px" alt="" />
      </div>
    );
  };

  const renderNavButtons = () => {
    return (
      <ul className="nav nav-tabs border-0 align-self-center" role="tablist">
        <li className="nav-item border-right">
          <a
            className="nav-link active"
            data-toggle="tab"
            href="#Company"
            role="tab"
          >
            Company
          </a>
        </li>
        <li className="nav-item border-right">
          <a
            className="nav-link disabled"
            data-toggle="tab"
            href="#MarketResearch"
            role="tab"
          >
            Market Research
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link disabled"
            data-toggle="tab"
            href="#Competition"
            role="tab"
          >
            Competition
          </a>
        </li>
      </ul>
    );
  };

  const renderCompanyOptions = () => {
    return (
      <div className="tab-pane active tabcomncls" id="Company" role="tabpanel">
        <div className="d-flex align-items-center align-align-self-center mt-3 mb-5">
          <div className="checktasks text-center position-relative border">
            <label className="form-check-label d-block" htmlFor="check001">
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check001"
                name="option1111"
                checked={checkedModel}
                onChange={(e) => setCheckedModel(!checkedModel)}
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">AI Models & Tasks</h4>
              </span>
            </label>
            {loading.workFlow && checkedModel && renderInlineLoader()}
          </div>

          <div className="checktasks text-center border border-left-0">
            <label
              className="form-check-label d-block position-relative"
              htmlFor="check002"
            >
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check002"
                name="option2222"
                checked={checkedDoc}
                onChange={(e) => setCheckedDoc(!checkedDoc)}
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Info & Docs.</h4>
              </span>
            </label>
            {loading.workFlow && checkedDoc && renderInlineLoader()}
          </div>
        </div>
      </div>
    );
  };

  const renderMarketOptions = () => {
    return (
      <div className="tab-pane tabcomncls" id="MarketResearch" role="tabpanel">
        <div className="d-flex align-items-start justify-content-center mt-3 mb-5 ">
          <div className="">
            <div className="checktasks text-center position-relative border">
              <label className="form-check-label d-block" htmlFor="check003">
                <input
                  type="checkbox"
                  className="form-check-input checkInputClick"
                  id="check003"
                  name="option1111"
                  value="something"
                />
                <span className="tskchk">
                  <h4 className="mb-0 h6 text-white">Social Media</h4>
                </span>
              </label>
            </div>
            <div id="div6" className="div3 hidden checkdvcnt text-center">
              <div className="d-flex align-items-center justify-content-center flex-nowrap gap-3 mt-2">
                <div className="cliablesoc">
                  <img src="images/google.svg" width="23" />
                </div>
                <div className="cliablesoc">
                  <img src="images/facebook.svg" width="23" />
                </div>
                <div className="cliablesoc">
                  <img src="images/instagram.svg" width="23" />
                </div>
                <div className="cliablesoc twitter">
                  <img src="images/twitter.svg" width="23" />
                </div>
              </div>
            </div>
          </div>

          <div className="checktasks text-center border border-left-0">
            <label
              className="form-check-label d-block position-relative"
              htmlFor="check004"
            >
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check004"
                name="option11111"
                value="something"
                data-target="div4"
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Supplier</h4>
              </span>
            </label>
          </div>

          <div className="checktasks text-center border border-left-0">
            <label
              className="form-check-label d-block position-relative"
              htmlFor="check005"
            >
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check005"
                name="option11111"
                value="something"
                data-target="div5"
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Publications</h4>
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderCompitationOptions = () => {
    return (
      <div className="tab-pane" id="Competition" role="tabpanel">
        <div className="d-flex justify-content-center mt-3 mb-5 ">
          <div className="checktasks text-center position-relative border">
            <label className="form-check-label d-block" htmlFor="check006">
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check006"
                name="option1111"
                value="something"
                data-target="div7"
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Competitor 1</h4>
              </span>
            </label>
          </div>

          <div className="checktasks text-center border border-left-0">
            <label
              className="form-check-label d-block position-relative"
              htmlFor="check007"
            >
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check007"
                name="option11111"
                value="something"
                data-target="div8"
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Competitor 2</h4>
              </span>
            </label>
          </div>

          <div className="checktasks text-center border border-left-0">
            <label
              className="form-check-label d-block position-relative"
              htmlFor="check008"
            >
              <input
                type="checkbox"
                className="form-check-input checkInputClick"
                id="check008"
                name="option11111"
                value="something"
                data-target="div9"
              />
              <span className="tskchk">
                <h4 className="mb-0 h6 text-white">Competitor 3</h4>
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  };
  const renderTabPanels = () => {
    return (
      <div className="tabpaelcnt px-5 align-self-center">
        <div className="tab-content">
          {renderCompanyOptions()}
          {renderMarketOptions()}
          {renderCompitationOptions()}
        </div>
      </div>
    );
  };

  const renderChatTypes = () => {
    return (
      <div className="tabpaelcnt px-5">
        <div className="text-white text-center mb-4">
          Choose any Resource to start conversation
        </div>
        {renderNavButtons()}
        {renderTabPanels()}
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <form noValidate onSubmit={onQuerySubmit}>
        <div className="d-flex align-items-center">
          <div className="border-bottom py-2 w-100 justify-content-between d-flex align-items-center">
            <input
              type="text"
              onChange={(e) => setQuery(e.target.value.slice())}
              value={query}
              className="askmeanything2 bg-none text-white border-0 w-75"
              placeholder="Ask Me Anything..."
            />

            <div className="checktasks text-center chatGpt">
              <label className="form-check-label d-block" htmlFor="chatGpt">
                <input
                  type="checkbox"
                  className="form-check-input checkInputClick"
                  id="chatGpt"
                  name="chatGpt"
                  checked={chatGpt}
                  onChange={(e) => setChatGpt(!chatGpt)}
                />
                <span className="tskchk">
                  <small className="text-white">
                    <i>Powered by ChatGPT</i>
                  </small>
                  <svg width="34" height="35" viewBox="0 0 39 40" fill="none">
                    <path
                      d="M19.2193 3.83988L19.2664 3.83753L19.3123 3.82642C19.3856 3.80864 19.4606 3.7994 19.5358 3.79883C22.8074 3.79977 25.5 6.56518 25.5 10.0005V20.0005C25.5 23.413 22.7968 26.1672 19.5 26.1672C16.2033 26.1672 13.5 23.413 13.5 20.0005V10.0008C13.5021 8.41213 14.0997 6.88729 15.1656 5.74127C16.2312 4.59557 17.6829 3.91628 19.2193 3.83988Z"
                      stroke="#fff"
                    ></path>
                    <path
                      d="M18.375 33.2183V32.7786L17.9389 32.7224C11.9502 31.9506 7.24628 26.8137 7.00937 20.5H9.26148C9.51682 26.049 13.9992 30.5 19.5 30.5C25.0008 30.5 29.4832 26.049 29.7385 20.5H31.9906C31.7538 26.8151 27.0499 31.9505 21.0611 32.7224L20.625 32.7786V33.2183V36.1667H18.375V33.2183Z"
                      stroke="#fff"
                    ></path>
                  </svg>
                </span>
              </label>
            </div>
          </div>
        </div>
        <small>
          *
          {chatGpt
            ? "Info returned from Uploaded Doc .If no info found then info returned from LLM model"
            : "Info returned from Uploaded Doc"}
        </small>
      </form>
    );
  };

  const renderChatContent = () => {
    return (
      <div className="d-flex flex-column gap-1 px-5 pt-5">
        {renderChatTypes()}
        {renderSearchForm()}
        <div className="mt-3">{renderChatResult()}</div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            {renderCloseModal()}
            <div className="col-md-2 px-0">
              <div className="chtbothstrybox text-white py-3">
                <div className="px-3">
                  <ChatbotFiles />
                </div>
                <div className="border-top"></div>
                <div className="px-3">
                  <ChatbotHistory />
                </div>
              </div>
            </div>
            <div className="col-md-8 px-0 chtbotmidsec">
              {renderChatContent()}
            </div>
            <div className="col-md-2 px-0">
              <AskMeRightPanel />
            </div>
          </div>
        </div>
      </>
    );
  };
  return render();
};

export default ChatbotResult;
