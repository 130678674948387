import React, { useState } from "react";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { Link, useNavigate } from "react-router-dom";
import { createAskMeWorkflow } from "../../../service/ai-model.service";
import { useAppContext } from "../../../context/app.context";
import constant, { ToastType } from "../../../helper/constant";
import CustomLoader from "../CustomLoader";
import { useAuthContext } from "../../../context/auth.context";
import { getUUID } from "../../../helper/helper";

const AskMeRightPanel = () => {
  const {
    askMeSelectedModels,
    updateAskMeSelectedModels,
    queryTasks,
    updateQueryTasks,
    updateShowModal,
    clearAskMe,
  } = useAIAskMeContext();
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAppContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  const onCreateWorkflow = () => {
    setLoading(true);
    const payload = {
      data: askMeSelectedModels,
      template_name: `${getUUID()}-auto`,
      industryId: userInfo?.org?.org_config.industry,
    };
    createAskMeWorkflow(payload)
      .then(({ id }) => {
        showAlert(
          `Workflow creation ${constant.MESSAGES.GENERAL_SUCCESS}`,
          ToastType.SUCCESS,
        );
        updateShowModal(false);
        clearAskMe();
        navigate(`/${constant.PAGES.HOME.AI_WORKFLOW}/${id}`);
      })
      .catch((ex) =>
        showAlert(
          ex?.message || constant.MESSAGES.COMMON_ERROR_MESSAGE,
          ToastType.ERROR,
          false,
        ),
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const removeAskMeModel = (model, index) => {
    let list = [...askMeSelectedModels];
    list.splice(index, 1);
    updateAskMeSelectedModels(list);
    //Task Update
    let taskList = [...queryTasks];
    taskList = taskList.map((item) => {
      if (item.task_id === model.task_id) item.isChecked = false;
      return item;
    });
    updateQueryTasks(taskList);
  };

  const renderSelectedModels = () => {
    if (askMeSelectedModels.length) {
      return askMeSelectedModels.map((item, key) => {
        const { Task, Department } = item;
        return (
          <li key={key}>
            <div>
              <div>{Task}</div>
              <div className="text-secondary text-uppercase">{Department}</div>
            </div>
            <Link onClick={() => removeAskMeModel(item, key)}>
              <svg width="30" height="36" viewBox="0 0 34 40" fill="none">
                <g clipPath="url(#clip0_1924_8667)">
                  <path
                    d="M11.3727 14.3119L22.6864 25.6256M22.6864 14.3119L11.3727 25.6256"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1924_8667">
                    <rect
                      width="33.9411"
                      height="39.9411"
                      fill="white"
                      transform="translate(0.0585938)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </li>
        );
      });
    }
  };
  const render = () => {
    return (
      <>
        {loading && <CustomLoader full />}
        <div
          className={`SelectedTasksbox text-white py-5 px-3 ${askMeSelectedModels.length ? "showselectedtask" : ""}`}
        >
          <h4 className="text-white mb-3 font-weight-bold h6">
            Selected Tasks
          </h4>
          <ul className="selctedtsklist mb-5">{renderSelectedModels()}</ul>
          <Link
            className="btn1 w-100 bg-white text-dark py-2 font15fontweight600"
            onClick={() => onCreateWorkflow()}
          >
            Create Workflow
          </Link>
        </div>
      </>
    );
  };
  return render();
};

export default AskMeRightPanel;
