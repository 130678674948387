import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import RightSidemenu from "../../common/RightSidemenu";
import ModelCreator from "../ModelCreator";
import RecommandedFeature from "./RecommandedFeature";
import constant, { TemplateStatus } from "../../../helper/constant";
import { getHtmlToImgData, hasNoEmptyObjects } from "../../../helper/helper";
import { ICProvider } from "../../../context/ai-ic.context";
import { useAppContext } from "../../../context/app.context";
import {
  getSocialMediaModel,
  getTrainingModel,
} from "../../../service/ai-model.service";

const AIData = () => {
  const params = useParams();
  const { updateLoader } = useAppContext();
  const {
    getAIModel,
    showRightMenu,
    aiDataFormValues,
    triggerModelProcess,
    models,
    activeTemplate,
    setAllAIModelFormData,
  } = useAIModelContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getAIModel(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (models.length) {
      const taskList = models.flatMap((model) => {
        return [...model.tasks];
      });
      const allPromises = taskList.map((task) => getAllTaskPromises(task));
      Promise.all(allPromises)
        .then((result) => {
          const list = result.map((item) => item[0] || {});
          setAllAIModelFormData(list);
        })
        .catch(console.log);
    }
  }, [models]);

  const getAllTaskPromises = (task) => {
    if (constant.LLMModels.includes(task.modelId))
      return getSocialMediaModel(task.taskId);
    else return getTrainingModel(task.taskId);
  };

  const isRunEnabled = () => {
    if (models.length) {
      const taskList = models.flatMap((model) => {
        return [...model.tasks];
      });
      if (
        taskList.length &&
        aiDataFormValues.length &&
        hasNoEmptyObjects(aiDataFormValues) &&
        aiDataFormValues.length === taskList.length
      )
        return true;
    }
    return false;
  };

  const onRun = () => {
    updateLoader(true);
    getHtmlToImgData()
      .then((data) => triggerTraining(data))
      .catch((ex) => triggerTraining());
  };

  const triggerTraining = (data) => {
    triggerModelProcess(params.id, data, (r) => {
      navigate(`/${constant.PAGES.HOME.AI_MODEL}/${params.id}`);
      updateLoader(false);
    });
  };

  const renderDataUploadMessage = () => {
    return (
      <p className="m-0 font-weight-bold">
        Please upload{" ("}
        <span>
          <svg width="18" height="17" viewBox="0 0 22 21" fill="none">
            <path
              d="M19 5C19 7.21 14.97 9 10 9C5.03 9 1 7.21 1 5M19 5C19 2.79 14.97 1 10 1C5.03 1 1 2.79 1 5M19 5V10M1 5V10M1 10C1 12.21 5.03 14 10 14M1 10V15C1 17.21 5.03 19 10 19"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M12 16H14V21H20V16H22L17 12L12 16Z" fill="black" />
          </svg>
        </span>
        {") "}
        training data for all models to proceed.
      </p>
    );
  };

  const render = () => {
    return (
      <div className="row mx-0 height-inherit">
        <div
          className={` px-md-0 ${showRightMenu ? "col-md-10" : "col-md-12"}`}
        >
          <div className="p-1">
            <div className="py-2 text-center gap-2">
              <ModelCreator />
              {renderDataUploadMessage()}
              {
                <button
                  className="btn1 btn radious-0  w-15 mt-2 f-13 text-uppercase py-2 font15fontweight600"
                  onClick={onRun}
                  disabled={!isRunEnabled()}
                >
                  Run
                </button>
              }
            </div>
          </div>
        </div>
        {showRightMenu && (
          <ICProvider>
            <RightSidemenu>
              <RecommandedFeature />
            </RightSidemenu>
          </ICProvider>
        )}
      </div>
    );
  };

  return render();
};

export default AIData;
