import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faDownload,
  faEdit,
  faPencil,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import constant, { ToastType } from "../../../../../helper/constant";
import { downloadCSV, formatDate } from "../../../../../helper/helper";
import { updateDocumentPrediction } from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import ZoomableImage from "../../../../common/ZoomImage";
const DocumentAITable = (props) => {
  const { columns, list, original, onSave } = props;
  const { selectedPredictionModel } = useAIModelContext();
  const { showAlert, updateLoader } = useAppContext();

  const [form, setForm] = useState({});
  const params = useParams();

  const onRowEdit = (listItem) => {
    listItem.isEdit = !listItem.isEdit;
    setForm({ ...listItem });
  };

  const onRowSave = (item, rowIndex) => {
    item.isEdit = !item.isEdit;
    const request = { ...original[rowIndex] };
    request.data.entity = request.data.entity.map((ent) => {
      ent.mentionText = form[ent.type];
      return {
        ...ent,
      };
    });
    updateLoader(true);
    updateDocumentPrediction(params.id, selectedPredictionModel.taskId, request)
      .then((res) => onSave())
      .catch((ex) => {
        updateLoader(false);
        showAlert("Update failed", ToastType.ERROR);
      });
  };

  const onDownloadCSV = () => {
    const { task_model } = selectedPredictionModel;
    const datetime = formatDate("", constant.csvDateFormat);
    const fileName = `${task_model}_${datetime}.csv`;
    downloadCSV(list, fileName);
  };

  const renderActions = (item, rowIndex) => {
    if (item.isEdit)
      return (
        <div className="d-flex flex-column gap-1">
          <Link
            className="btn cursor-pointer text-success"
            onClick={() => onRowSave(item, rowIndex)}
          >
            <FontAwesomeIcon icon={faCheck} alt="" size="lg" />
          </Link>
          <Link
            className="btn cursor-pointer text-danger"
            onClick={() => (item.isEdit = !item.isEdit)}
          >
            <FontAwesomeIcon icon={faTrash} alt="" size="md" />
          </Link>
        </div>
      );
    return (
      <Link
        className="btn btnEdit text-blue cursor-pointer"
        onClick={() => onRowEdit(item, rowIndex)}
      >
        <FontAwesomeIcon icon={faPencil} alt="Edit" />
      </Link>
    );
  };

  const renderRow = (item, col) => {
    if (col !== "image") {
      if (item.isEdit)
        return (
          <input
            className="form-control"
            type="text"
            value={form[col]}
            placeholder={col}
            onChange={(e) => {
              form[col] = e.target.value;
              setForm({ ...form });
            }}
          />
        );
      return (
        <p className="m-0 p-0" datacol={col} dataval={item[col]}>
          <span className="mobile-content">{item[col]}</span>
        </p>
      );
    }
    return <ZoomableImage src={item[col]} alt="img" />;
  };

  const render = () => {
    return (
      <>
        <div className="d-flex justify-content-start">
          <button
            className="btn1 btn-document-ai-download"
            disabled={!list.length}
            onClick={() => onDownloadCSV()}
          >
            Download Report <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
        <div className="table-container">
          <table className="imgdetctble table align-middle">
            <thead>
              <tr>
                {columns.map((col, key) => (
                  <th key={key} scope="col">
                    {col}
                  </th>
                ))}
                <th scope=""></th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {columns.map((col, colIndex) => (
                      <td
                        key={colIndex}
                        className={
                          col === "image"
                            ? !item[col].includes(".pdf")
                              ? "w-15"
                              : ""
                            : ""
                        }
                      >
                        {renderRow(item, col, colIndex)}
                      </td>
                    ))}
                    <td>{renderActions(item, rowIndex)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  return render();
};

export default DocumentAITable;
