let sharedInfo = {};

export const setSharedInfo = (key, data) => {
  if (key) sharedInfo[key] = data;
};

export const getSharedInfo = (key) => {
  return sharedInfo[key];
};

export const clearSharedInfo = (key) => {
  sharedInfo[key] = null;
};
