import React, { useEffect, useState } from "react";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { formatToCurrency } from "../../../helper/helper";
import { Link } from "react-router-dom";
import constant from "../../../helper/constant";
import images from "../../../helper/images";
import { postChatboxQuestion } from "../../../service/ai-model.service";

const ChatQueyResult = (props) => {
  const { queryTasks, queryWorkflows, currentQuery } = useAIAskMeContext();
  const [docResult, setDocResult] = useState();
  const { chatGpt } = props;

  useEffect(() => {
    if (props.checkedDoc) {
      const query = currentQuery;
      postChatboxQuestion({ query, chatGpt })
        .then((result) => setDocResult(result))
        .catch((ex) => ex)
        .finally((ex) => props.stopLoader());
    } else {
      props.stopLoader();
    }
  }, [props.checkedDoc]);

  const renderExistingWorkflows = () => {
    if (queryWorkflows && queryWorkflows.length)
      return (
        <div className="border-bottom pb-5">
          <h4 className="text-white mb-3">Previous Relevant Workflows</h4>
          <div className="row">
            {queryWorkflows.map((item, index) => (
              <ExistingWorkflow item={item} key={index} />
            ))}
          </div>
        </div>
      );
  };

  const renderRelevantTasks = () => {
    if (queryTasks && queryTasks.length)
      return (
        <div className="border-bottom py-3">
          <h4 className="text-white mb-3">Relevant Tasks</h4>
          <div className="row">
            {queryTasks.map((item, key) => (
              <TaskCard item={item} key={item.task_id} />
            ))}
          </div>
        </div>
      );
  };

  const renderDocs = () => {
    if (props.checkedDoc && docResult)
      return (
        <div className="p-3 questaccordionbox">
          <div id="accordion02">
            <div className="pt-3">
              <div id="headingTwo">
                <div
                  className="btn-link collapsed border-0"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Info & Docs
                </div>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                data-parent="#accordion02"
              >
                <div className="mt-3">
                  <p className="font-weight-bold">{docResult.query}</p>
                  <p className="">{docResult.answer}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };

  const renderModels = () => {
    if (props.checkedModel)
      return (
        <div className="border-bottom questaccordionbox p-3 mt-2">
          <div id="accordion01">
            <div id="headingOne">
              <div
                className="btn-link collapsed border-0"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Previous Relevant Workflows
              </div>
            </div>
            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion01"
            >
              <div className="pt-3">
                {renderExistingWorkflows()}
                {renderRelevantTasks()}
              </div>
            </div>
          </div>
        </div>
      );
  };

  const render = () => {
    return (
      <div className="chtbotmidsec text-white mt-3">
        {renderModels()}
        {renderDocs()}
      </div>
    );
  };
  return render();
};

export default ChatQueyResult;

const ExistingWorkflow = (props) => {
  const { template_id, url, template_name, reason, active } = props.item;

  const renderCheckModel = () => {
    if (active)
      return (
        <Link
          to={`/${constant.PAGES.AI_PREDICTION}/${template_id}`}
          className="chkpdctiontxt w-100 text-center py-2 bg-white text-dark"
        >
          {template_name}
        </Link>
      );
    return (
      <div className="bold">Model under development prediction not allowed</div>
    );
  };

  const renderReason = () => {
    if (reason.length)
      return reason.map((point, pointIndex) => (
        <li key={pointIndex}>{point}</li>
      ));
  };

  const render = () => {
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 my-2">
        <div className="chkpdctionsec mb-2 position-relative">
          <h4 className="mb-2 h6 text-white">{template_name}</h4>
          <div>
            <img
              src={url}
              alt={template_name}
              className="w-100 askme-workflow-preview"
            />
          </div>
          <div className="chkpdctionhover p-4">{renderCheckModel()}</div>
        </div>
        <h5 className="mb-2 text-white">Reasons to add :</h5>
        <small className="text-secondary">
          <ul className="ul-bullet">{renderReason()}</ul>
        </small>
      </div>
    );
  };

  return render();
};

const TaskCard = (props) => {
  const {
    queryTasks,
    askMeSelectedModels,
    updateAskMeSelectedModels,
    updateQueryTasks,
  } = useAIAskMeContext();
  const {
    task_id,
    Task,
    Department,
    employees,
    salary,
    reason,
    isChecked = false,
  } = props.item;

  const onChange = (value) => {
    //Task Update
    let taskList = [...queryTasks];
    let matchItem = {};
    taskList = taskList.map((item) => {
      if (item.task_id === value) {
        item.isChecked = !item.isChecked;
        matchItem = item;
      }
      return item;
    });
    updateQueryTasks(taskList);

    // Update selected models
    let modelList = [...askMeSelectedModels];
    if (!matchItem.isChecked)
      modelList = modelList.filter(
        (item) => item.task_id !== matchItem.task_id
      );
    else modelList.push(matchItem);
    updateAskMeSelectedModels(modelList);
  };

  const renderReason = () => {
    if (reason.length)
      return reason.map((point, pointIndex) => (
        <li key={pointIndex}>{point}</li>
      ));
  };
  const render = () => {
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 my-2">
        <div className="checktasks m-0">
          <label
            className="form-check-label d-block"
            htmlFor={`check-${task_id}`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id={`check-${task_id}`}
              name={`check-${task_id}`}
              value={task_id}
              onChange={(e) => onChange(e.target.value)}
              checked={isChecked}
            />
            <span className="tskchk">
              <h4 className="mb-2 h6 text-white">{Task}</h4>
              <div className="text-uppercase mb-4">{Department}</div>
              <div className="mb-2">
                No. of Employees :{" "}
                <span className="text-secondary">{employees}</span>
              </div>
              <div className="mb-4">
                Average Salary :{" "}
                <span className="text-secondary">
                  {formatToCurrency(salary)}
                </span>
              </div>
              <h6 className="mb-2 text-white">Reasons to add :</h6>
              <small className="text-secondary">
                <ul className="ul-bullet">{renderReason()}</ul>
              </small>
            </span>
          </label>
        </div>
      </div>
    );
  };

  return render();
};
